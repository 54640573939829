.mobile2 {
  display: flex;
  flex-direction: column;
}
.mobile2 .banner-mobile2 {
  height: 700px;
  background-size: cover;
  padding: 0 30px;
  z-index: -2;
}

.mobile2 .form {
  min-height: 550px;
  z-index: -3;
}

.mobile2 .header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}
.mobile2 .header .title {
  color: rgba(255, 255, 255, 0.8);

  font-size: 50px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  line-height: 50px;
}
.mobile2 .header .sub-title {
  font-family: "Segoe UI", sans-serif;
  color: #012718;
  font-size: 24px;
  margin-top: -25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.mobile2 form {
  display: flex;
  position: relative;
  bottom: -380px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-size: cover;
  background-color: rgba(888, 888, 888, 0.6);
  margin-right: 150px;
  padding-top: 25px;
  flex-direction: column;

  padding: 30px;
}

.mobile2 .input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  margin-bottom: 30px;
}
.mobile2 .input input {
  padding: 5px 16px;
  color: #666;
  font-family: "Work Sans", sans-serif;
  border: none;
  border-bottom: 2px solid #595959;
  background: transparent;
  height: 40px;
}

.mobile2 label {
  font-family: "Work Sans", sans-serif;
  color: #010927 !important;
  font-size: 14px;
  text-transform: uppercase;
}

.mobile2 input[type="file"] {
  display: none;
}

.v .upload {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.upload-label {
  background: rgb(212, 213, 217);
  padding: 10px;
  font-size: 14px;
}
.button {
  width: 100%;
  margin: 0 auto;
  display: flex;
}
.button button {
  background: #ff6d00;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  margin: 20px 0;
  margin-left: auto;
  outline: 0;
  border: 0;
  height: 50px;
  width: 120px;
  border-radius: 25px;
  color: #fff;
}
