@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Poppins:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap);
/* Basic styling */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body {
  font-family: sans-serif;
  font-size: 16px;
}
nav {
  background: #fff;
  padding: 0 15px;
  width: 100%;

  max-width: 1000px;
  margin: 0 auto;
}
a {
  color: #1f1f1f;
  text-decoration: none;
  text-transform: uppercase;
}
.menu,
.submenu {
  list-style-type: none;
}
.logo {
  /* background: red; */
  width: 200px;
  height: 20px;
}
.logo img {
  width: 120px;
  height: 20px;
}
.item {
  padding: 10px;
}
.item.button {
  padding: 9px 5px;
}
.item:not(.button) a:hover,
.item a:hover::after {
  color: #ff6c00;
}

/* Mobile menu */
.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.menu li a {
  display: block;
  padding: 15px 5px;
}
.menu li.subitem a {
  padding: 15px;
}
.toggle {
  order: 1;
  font-size: 20px;
}
.item.button {
  order: 2;
}
.item {
  order: 3;
  width: 100%;
  text-align: center;
  display: none;
}
.active .item {
  display: block;
}
.button.secondary {
  /* divider between buttons and menu links */
  border-bottom: 1px #444 solid;
}

/* Submenu up from mobile screens */
.submenu {
  display: none;
}
.submenu-active .submenu {
  display: block;
}
.has-submenu i {
  font-size: 12px;
}

.subitem a {
  padding: 10px 15px;
}
.submenu-active {
  background-color: #fff;
  border-radius: 3px;
}

/* Tablet menu */
@media all and (min-width: 700px) {
  .menu {
    justify-content: center;
  }
  .logo {
    flex: 1 1;
  }
  .item.button {
    width: auto;
    order: 1;
    display: block;
  }
  .toggle {
    flex: 1 1;
    text-align: right;
    order: 2;
  }
  /* Button up from tablet screen */
  .menu li.button a {
    padding: 10px 15px;
    margin: 5px 0;
  }
  .button a {
    background: #0080ff;
    border: 1px royalblue solid;
  }
  .button.secondary {
    border: 0;
  }
  .button.secondary a {
    background: transparent;
    border: 1px #0080ff solid;
  }
  .button a:hover {
    text-decoration: none;
  }
  .button:not(.secondary) a:hover {
    background: royalblue;
    border-color: darkblue;
  }
}

/* Desktop menu */
@media all and (min-width: 960px) {
  .menu {
    align-items: center;
    flex-wrap: nowrap;
    height: 60px;
    background: none;
  }
  .logo {
    order: 0;
  }
  .item {
    order: 1;
    position: relative;
    display: block;
    width: auto;
  }
  .button {
    order: 2;
  }
  .submenu-active .submenu {
    display: block;
    position: absolute;
    left: 0;
    top: 68px;
    background: #fff;
  }
  .toggle {
    display: none;
  }
  .submenu-active {
    border-radius: 0;
  }
}

.mobile {
  display: flex;
  flex-direction: column;
  z-index: 0;
}
.slide {
  background: red;
  position: relative;
}
.slide .button {
  position: absolute;
  left: 50%;
  bottom: 15px;
  width: 200px;
  align-items: center;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);

  background: transparent;
  border: 2px solid #fff;
  padding: 3px 20px;
  border-radius: 50px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.button .title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}
.button .sub-title {
  font-size: 9px;
  font-weight: bold;
}

.info {
  display: flex;
  justify-content: center;
  padding: 20px 40px;
  flex-direction: column;
}
.header-mobile {
  margin-bottom: 20px;
}

.header-mobile .title {
  color: #d7fff6;
  font-size: 26pt;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
.header-mobile .sub-title {
  font-family: "Segoe UI", sans-serif;
  font-weight: bold;
  color: #010927;
  font-size: 24pt;
  margin-top: -25px;
}
.infos {
  display: flex;
  flex-direction: column;
  font-family: "Work Sans", sans-serif;
  line-height: 20px;
}
.info-1 p {
  font-size: 14px;
  color: #37426b;
}
.info-2 p.title {
  font-weight: bold;
  font-size: 16px;
  font-family: "Segoe UI", sans-serif;
  margin-bottom: 10px;
}

.info-2,
.info-3 {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}
.info-2 .aside-info {
  margin-left: 10px;
  font-size: 15px;
}
.info-3 > div {
  margin-left: 10px;
  font-family: "Segoe UI", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
}
.lala {
    
    width: 140px;
    background: #ff6d00;
    border: 0;
    font-Family: sans-serif;
    font-Size: 12px;
    color: #fff;
    height: 40px;
    border-Radius: 10px;
  
}
section .header-mobile .title {
  font-size: 40px !important;
}
section .header-mobile .sub-title {
  line-height: 40px;
  text-align: right;
  font-size: 29px;
}
.man-mobile {
  width: 100%;
}
.header-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.header-item span {
  margin-left: 15px;
  font-weight: bold;
  font-family: "Segoe UI", sans-serif;
  font-size: 18px;
}

section .item {
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  color: #222;
  padding-bottom: 20px;
  border-bottom: 2px solid #ff6900;
  margin-bottom: 20px;
  display: flex !important;
  flex-direction: column;
}

.form-mobile {
  background-size: cover;
  background-color: #edebec;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.form-mobile .h3 {
  font-family: "Segoe UI", sans-serif;
  background: #ff6900;
  color: #fff;
  padding: 10px;
  font-size: 22px;
  margin: 10px 0 20px;
  border-radius: 25px;
  font-weight: bold;
}
.form-mobile .h4 {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  margin: 0 20px;
  text-align: center;
  line-height: 20px;
  margin-bottom: 20px;
}

.form-mobile .title {
  color: #fff;
  font-size: 48px;
  font-family: "Montserrat", sans-serif;
}
.form-mobile .sub-title {
  font-size: 28px;
  margin-top: -20px;
  text-align: center;
  color: #012718;
  font-family: "Segoe UI", sans-serif;
}

.form-mobile .input {
  display: flex;
  flex-direction: column;
  font-family: "Work Sans", sans-serif;
  color: #595959;
  padding: 0 40px;
  width: 100%;
}
.form-mobile input {
  padding: 5px 16px;
  color: #666;
  font-family: "Work Sans", sans-serif;
  border: none;
  border-bottom: 2px solid #595959;
  background: transparent;
  height: 40px;
  width: 100%;
  margin-bottom: 30px;
}

.form-mobile .input label {
  color: #010927;
  text-transform: uppercase;
}
.ast {
  color: red;
  font-weight: bold;
}

.submit button {
  background: #ff6d00;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  margin: 20px 0;
  margin-left: auto;
  outline: 0;
  border: 0;
  height: 50px;
  width: 120px;
  border-radius: 25px;
  color: #fff;
}

.prices-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 100px;
}
.prices-mobile .title {
  font-size: 38px;
}
.prices-mobile .sub-title {
  font-size: 30px;
  text-align: center;
  color: #012718;
}
.prices-mobile .text {
  font-family: "Work Sans", sans-serif;
  color: #010927;
  font-size: 14px;
  margin-bottom: 20px;
}
.price-box {
  margin-bottom: 25px;
}
.price-header {
  height: 195px;
  background: #ff7a1d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}
.price-header .title {
  font-size: 20px;
}
.price-header .price {
  font-size: 50px;
  font-weight: bolder;
}
.price-header .price sup {
  font-size: 20px;
}
.price-body {
  background: #00ffe7;
  padding: 0 20px;
  box-shadow: 1px 2px #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0;
}
.price-body li {
  font-family: "Montserrat", sans-serif;
  color: #7b7878;
  text-align: center;
  margin: 15px 0;
}

.price-body button {
  width: 140px;
  background: #ff6d00;
  border: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  color: #fff;
  height: 40px;
  border-radius: 10px;
}

.banner-button {
  width: 200px;
}

.group-item {
  height: 500px;
}

.banner {
  min-height: 700px;
  background-size: cover;
  background-position: top;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: -1;
}
.header-mobile {
  font-family: "Segoe UI", sans-serif;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-mobile .title {
  font-size: 34px;
  color: #d7fff6;
}
.header-mobile .sub-title {
  color: #010927;
  font-size: 26pt;
}

.login-box-mobile {
  margin-top: 40px;
  background: #fff;
  height: 260px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  border-radius: 3px;
  justify-content: space-evenly;
}

.login-box-mobile h2 {
  font-family: "Segoe UI", sans-serif;
  color: #010927;
  font-size: 20pt;
  margin-bottom: -20px;
  font-weight: bold;
}

.login-box-mobile input {
  border: 0;
  border-bottom: 2px solid #ff6c00;
  padding: 8px 16px;
  background: transparent;
}
.login-box-mobile label {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  color: #010927;
}
.login-box-mobile label,
input {
  font-family: "Montserrat", sans-serif;
  color: #7b7878;
}

.login-box-mobile .input {
  display: flex;
  flex-direction: column;
}
.content-mobile {
  display: flex;
  flex-direction: column;
  background-size: cover;
  padding-bottom: 60px;
}
.btn-group-mobile {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-evenly;
}
.btn-group-mobile .button {
  border: 2px solid #4deed7;
  border-radius: 15px;
  height: 90px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.button-box-mobile {
  text-align: center;
  width: 120px;
}
.button-box-mobile i {
  color: #fff;
  font-size: 50pt;
}
.button-box-mobile span {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
}

.texts {
  padding: 0 30px;
  margin-bottom: 40px;
}
.texts p {
  font-family: "Segoe UI", sans-serif;
  color: #fff;
  font-size: 14px;
}

.wpp-button-mobile {
  border-radius: 15px;
  margin: 0 40px;
  width: 60%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 2px solid #4deed7;
}
.wpp-button-mobile i {
  font-size: 50px;
  color: #ff6c00;
}
.wpp-button-mobile > div {
  display: Flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
  color: #fff;
  margin-left: 10px;
}

.wpp-button-mobile > div .title {
  font-family: "Segoe UI", sans-serif;

  font-weight: bold;
}
.wpp-button-mobile > div .cel {
  font-family: "Work Sans", sans-serif;
}

.banner-mobile {
  display: flex;
  padding-bottom: 60px;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  z-index: -1;
}

.header-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 20px 0;
}
.header-mobile .title {
  font-size: 38px;
  text-align: center;
}
.header-mobile .sub-title {
  font-size: 30px;
}
.button-mobile {
  display: flex;
  border: 2px solid #4deed7;
  min-width: 240px;
  align-items: center;
  padding: 10px 20px;
  border-radius: 15px;
  margin-bottom: 40px;
}
.button-mobile p {
  font-family: "Segoe UI", sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 14px;
}
.button-mobile div {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
.button-mobile span {
  font-family: "Work Sans", sans-serif;
  color: #fff;
  font-size: 14px;
}
.button-mobile i {
  font-size: 40px;
  color: #fff;
  font-weight: bold;
}

.media-group-mobile {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
  margin: 0 auto;
}
.media-group-mobile span {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
.button-mobile-case {
  display: flex;
  text-align: center;
  width: 100px;
  flex-direction: column;
  margin-top: 45px;
}
.button-mobile-case .media-button {
  border: 2px solid #4deed7;
  border-radius: 8px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-mobile-case .media-button i {
  color: #fff;
  font-size: 50px;
}

.mobile2 {
  display: flex;
  flex-direction: column;
}
.mobile2 .banner-mobile2 {
  height: 700px;
  background-size: cover;
  padding: 0 30px;
  z-index: -2;
}

.mobile2 .form {
  min-height: 550px;
  z-index: -3;
}

.mobile2 .header {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}
.mobile2 .header .title {
  color: rgba(255, 255, 255, 0.8);

  font-size: 50px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  line-height: 50px;
}
.mobile2 .header .sub-title {
  font-family: "Segoe UI", sans-serif;
  color: #012718;
  font-size: 24px;
  margin-top: -25px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.mobile2 form {
  display: flex;
  position: relative;
  bottom: -380px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-size: cover;
  background-color: rgba(888, 888, 888, 0.6);
  margin-right: 150px;
  padding-top: 25px;
  flex-direction: column;

  padding: 30px;
}

.mobile2 .input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  margin-bottom: 30px;
}
.mobile2 .input input {
  padding: 5px 16px;
  color: #666;
  font-family: "Work Sans", sans-serif;
  border: none;
  border-bottom: 2px solid #595959;
  background: transparent;
  height: 40px;
}

.mobile2 label {
  font-family: "Work Sans", sans-serif;
  color: #010927 !important;
  font-size: 14px;
  text-transform: uppercase;
}

.mobile2 input[type="file"] {
  display: none;
}

.v .upload {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.upload-label {
  background: rgb(212, 213, 217);
  padding: 10px;
  font-size: 14px;
}
.button {
  width: 100%;
  margin: 0 auto;
  display: flex;
}
.button button {
  background: #ff6d00;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  margin: 20px 0;
  margin-left: auto;
  outline: 0;
  border: 0;
  height: 50px;
  width: 120px;
  border-radius: 25px;
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}
.arrow-prev {
  position: absolute;
  z-index: 9;
}

