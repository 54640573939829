@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Poppins:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}
.arrow-prev {
  position: absolute;
  z-index: 9;
}
